import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// export const formatDate = (laTime) => {
//   const placeholderPattern = /^0000-00-00 00:00:00$/;

//   // Check if the date string matches the pattern. Mence date is empty then return empty date.
//   if (placeholderPattern.test(laTime)) {
//     return laTime;
//   }

//   // Define the time zones
//   const laTimeZone = "America/Los_Angeles";
//   const istTimeZone = "Asia/Kolkata";

//   // Convert Los Angeles time to UTC
//   const utcDate = dayjs.tz(laTime, laTimeZone);

//   // Convert UTC to IST
//   const istDate = utcDate.tz(istTimeZone);

//   // Format the date and time in IST
//   const formattedDateInIST = istDate.format("D MMM YYYY, HH:mm:ss");

//   return formattedDateInIST.toUpperCase();
// };

export function formatDate(date) {
  const placeholderPattern = /^0000-00-00 00:00:00$/;

  // Check if the date string matches the pattern. Mence date is empty then return empty date.
  if (placeholderPattern.test(date)) {
    return date;
  }

  const istTime = dayjs(date).local().format("D MMM YYYY, HH:mm:ss");
  return istTime;
}

import React, { useEffect } from "react";
import Label from "../shared/Label";
import lttlFabIcon from '../../assets/lttlFabIcon.png';


const QrCodePreview = ({
  url,
  width,
  isHideLabel = false,
  id,
  downloadQrCode,
  fabIcon
}) => {
  console.log("fabIcon", fabIcon)
  // useEffect(() => {
  //   new window.QrCodeWithLogo({
  //     canvas: document.getElementById("canvas"),
  //     content: url,
  //     width: width ? width : 300,
  //     download: false,
  //     image: document.getElementById(id),
  //     logo: {
  //       src: fabIcon ?? lttlFabIcon,
  //       logoSize: 0.2,
  //     },
  //   });

  //   const dwonloadqrcode = new window.QrCodeWithLogo({
  //     canvas: document.getElementById("canvas"),
  //     content: url,
  //     width: 500,
  //     download: false,
  //     logo: {
  //       src: fabIcon ?? lttlFabIcon,
  //       logoSize: 0.2,
  //     },
  //   });
  //   if (downloadQrCode) downloadQrCode(dwonloadqrcode);

  // }, [url]);

  useEffect(() => {
    const generateQrCode = (canvasId, content, logoSrc, qrWidth, id) => {
      return new window.QrCodeWithLogo({
        canvas: document.getElementById(canvasId),
        content: content,
        width: qrWidth,
        image: document.getElementById(id),
        download: false,
        logo: {
          src: logoSrc,
          logoSize: 0.2,
        },
      });
    };

    // Generate the main preview QR code
    generateQrCode("canvas", url, fabIcon ?? lttlFabIcon, width, id);

    // Generate the downloadable QR code (if needed)
    if (downloadQrCode) {
      const qrCodeForDownload = generateQrCode("canvas", url, fabIcon ?? lttlFabIcon, 500);
      downloadQrCode(qrCodeForDownload);
    }

  }, [url]);

  return (
    <div className="flex  flex-col justify-center items-center text-center">
      {!isHideLabel && (
        <div className="">
          <Label label={"Preview"} className={"text-muted"} />
        </div>
      )}

      <div className="qr-wraper" id="qrCode">
        <img src="" alt="Qr Code" id={id} />
      </div>
    </div>
  );
};

export default QrCodePreview;

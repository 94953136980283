const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const utcToLocal = (date) => {
  // Convert the date to the local timezone
  const localTime = dayjs.utc(date).local().format("D MMM YYYY, HH:mm:ss");
  return localTime;
};

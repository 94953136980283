import React, { useState } from 'react';
import PrimaryDialog from '../shared/PrimaryModal';
import { ImageDropZone } from '../shared/DropZone';
import { Checkbox } from '@mantine/core';
import { Button } from '@mantine/core';
import InputControl from '../shared/InputControl';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { brandLogoService } from '../../service/module/brandLogo';
import { toast } from 'react-toastify';

export function UploadNewIcon({ open, setOpen }) {
    const methods = useForm();
    const [file, setFile] = useState(null); // state to hold the selected file
    const queryClient = useQueryClient();

    const uploadNewIconMutation = useMutation({
        mutationFn: ({ formData }) => brandLogoService.uploadBrandLogo(formData),
        mutationKey: ['uploadNewIcon']
    })

    const onSubmit = (data) => {
        const formData = new FormData();

        if (!file) {
            alert("Please Select File")
            return false;
        }

        formData.append('logo_name', data.logo_name);
        formData.append('is_default', data.default_icon);
        formData.append('logo', file);


        uploadNewIconMutation.mutate({ formData }, {
            onSuccess: (data) => {
                toast.success(data.message); // 
                queryClient.invalidateQueries({
                    queryKey: ['getBrandsLogo']
                }) // Invalidate the Brand Logo. 
                setOpen(prv => !prv); // Close the modal. 
            },
            onError: (err) => [
                toast.error(err.response.data?.message)
            ]
        })
    };

    return (
        <FormProvider {...methods}>
            <PrimaryDialog title={<span className=' font-semibold'>Upload New Logo</span>} open={open} onClose={() => setOpen(false)}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className=" flex flex-col gap-2">
                        <ImageDropZone onFileSelect={setFile} selectedFile={file} />
                        <Checkbox label={"Default Icon"} {...methods.register('default_icon')} />
                        <InputControl required mt='' label={"Icon Name"} hintText={"Enter Icon Name e.g, LTTL Logo"} name={"logo_name"} />
                        <Button loading={uploadNewIconMutation.isPending} type="submit">Upload</Button>
                    </div>
                </form>
            </PrimaryDialog>
        </FormProvider>
    );
}

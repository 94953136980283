import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context/Context";
import { MdKeyboardArrowLeft, MdChevronRight } from "react-icons/md";

const Sidebar = ({
  open,
  handleTabChange,
  handleDropdownToggle,
  activeTab,
  activeDropdown,
  handleMenuToggle,
}) => {
  const contextStore = useContext(Context);
  const dashboardConfig1JSON = contextStore.dashboardConfig;
  const location = useLocation();
  const pathname = location.pathname;

  const handleItemClick = (menuItem) => {
    handleTabChange(menuItem.menuLabel.toLowerCase());
    handleDropdownToggle(menuItem);
    if (!open) {
      handleMenuToggle();
    }
  };
  const handleSubmenuItemClick = (menuItem) => {
    handleTabChange(menuItem.path);
  };

  return (
    <motion.div
      id="grafana-dashboar"
      className="shadow-md lyttl-sidebar  relative mt-[50px] h-screen  pt-4 transition duration-300 bg-white dark:bg-[#1F1F1F] "
      style={{
        width: open ? "200px" : "65px",
        paddingBottom: "100px",
      }}
      initial={{ width: 0 }}
      animate={{ width: open ? 200 : 65 }}
      transition={{
        duration: 0.2,
        type: "spring",
        damping: 15,
      }}
    >
      {/*Toggle the Sidebar  */}
      <div
        title={open ? "Collapse" : "Expand"}
        className="absolute flex toggle-menu-btn hover:text-white dark:bg-primary dark:hover:bg-softPrimary hover:bg-brand border border-gray-200 cursor-pointer right-[-11px] z-50 top-[3%] shadow-lg w-[24px] h-[24px] bg-white rounded-full items-center justify-center"
      >
        <div>
          {open ? (
            <MdKeyboardArrowLeft size={16} onClick={() => handleMenuToggle()} />
          ) : (
            <MdChevronRight size={16} onClick={() => handleMenuToggle()} />
          )}
        </div>
      </div>

      <ul className="flex flex-col  gap-2 overflow-hidden px-2 ">
        {dashboardConfig1JSON?.dashboards[0].menuItems.map(
          (menuItem, index) => (
            <div
              key={index}
              className="dashboard-nav-dropdown cursor-pointer relative group"
            >
              <NavLink
                replace
                to={menuItem?.path}
                className={` px-4 py-2 gap-2  cursor-pointer flex relative items-center rounded-lg hover:bg-gray-100 dark:hover:bg-softPrimary hover:!text-brand 
                    ${pathname === menuItem?.path
                    ? "bg-gray-100   dark:bg-softPrimary "
                    : ""
                  } `}
                onClick={() => handleItemClick(menuItem)}
              >
                <i
                  className={`fas  ${menuItem.icon} text-icon ${pathname === menuItem?.path ? "!text-brand  " : ""
                    }`}
                ></i>
                {open ? (
                  <div className="menuItem nav-link  flex-1">
                    <p
                      className={`text-textColor dark:text-white text-sm ${pathname === menuItem?.path ? "!text-brand" : ""
                        }
                        `}
                    >
                      {menuItem.menuLabel}
                    </p>
                    <motion.span
                      id="chevron-icon"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <i
                        className={`fas text-icon ${menuItem.subMenuItems.length > 0
                          ? activeDropdown !== menuItem.menuLabel
                            ? "fa-chevron-right"
                            : "fa-chevron-down"
                          : ""
                          }`}
                      ></i>
                    </motion.span>
                  </div>
                ) : (
                  ""
                )}
              </NavLink>
              <AnimatePresence>
                {activeDropdown === menuItem.menuLabel && (
                  <motion.div
                    className=""
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {open &&
                      menuItem.subMenuItems.map((subMenuItem, subIndex) => (
                        <NavLink
                          to={subMenuItem?.path}
                          state={{ data: { url: subMenuItem.id } }}
                          key={subMenuItem.id}
                          onClick={() => handleSubmenuItemClick(subMenuItem)}
                          className={` ${pathname === subMenuItem?.path
                            ? "bg-gray-100 dark:bg-softPrimary text-brand"
                            : ""
                            } px-8 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-softPrimary nav-link flex gap-2 items-center`}
                        >
                          <motion.p
                            className={`text-textColor dark:text-white  text-sm ${pathname === subMenuItem?.path
                              ? "!text-brand "
                              : ""
                              }} `}
                            initial={{ opacity: 0, x: 30 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                              duration: 0.2,
                              delay: subIndex * 0.1,
                            }}
                          >
                            {subMenuItem.name}
                          </motion.p>
                        </NavLink>
                      ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )
        )}
      </ul>
    </motion.div>
  );
};

export default Sidebar;

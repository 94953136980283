import { Group, Text, rem } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { toast } from 'react-toastify';

export function ImageDropZone({ onFileSelect, selectedFile }) {

    const handleFileError = (files) => {
        const [{ errors }] = files;
        const [{ code }] = errors;
        if (code === 'file-invalid-type') {
            toast.error('Unsupported file format. Please upload a PNG, JPEG, or JPG image')
        }
        if (code === "file-too-large") {
            toast.error('File size exceeds the 3 MB limit. Please upload an image smaller than 3 MB')
        }

    }

    return (
        <Dropzone
            onDrop={(files) => {
                onFileSelect(files[0]); // Pass the first selected file to the parent
            }}
            onReject={handleFileError}
            maxSize={3 * 1024 ** 2}
            accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.gif]}

        >
            <Group justify="center" gap="xs" style={{ pointerEvents: 'none' }}>
                <Dropzone.Accept>
                    <IconUpload
                        style={{ width: rem(30), height: rem(55), color: 'var(--mantine-color-blue-6)' }}
                        stroke={1.5}
                    />
                </Dropzone.Accept>

                <Dropzone.Reject>
                    <IconX
                        style={{ width: rem(30), height: rem(55), color: 'var(--mantine-color-red-6)' }}
                        stroke={1.5}
                    />
                </Dropzone.Reject>

                <Dropzone.Idle>
                    <IconPhoto
                        style={{ width: rem(30), height: rem(69), color: 'var(--mantine-color-dimmed)' }}
                        stroke={1.5}
                    />
                </Dropzone.Idle>

                <div>
                    {
                        selectedFile ? (
                            <Text size="xs" inline>
                                {selectedFile.name} Image Selected
                            </Text>
                        ) : (
                            <Text size="xs" inline>
                                Drag Custom Icon or Click to upload
                            </Text>
                        )
                    }
                </div>
            </Group>
        </Dropzone >
    );
}

import { useState } from 'react';
import { Table, Checkbox, Stack, Tooltip, ActionIcon, Text, Loader } from '@mantine/core';
import CpButton from '../Links/CpButton';
import { modals } from '@mantine/modals';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deepLinkService } from '../../service/module/deepLinkService';
import { toast } from 'react-toastify';
import { utcToLocal } from '../../helper/utcToLocal';

const tableHead = [
    {
        title: "Link Name",
        id: 1
    },
    {
        title: "URL",
        id: 2
    },
    {
        title: "Deep Link URL",
        id: 3
    },
    {
        title: "Created At",
        id: 4
    },
    {
        title: "Action",
        id: 5
    },
]

export function DynamicLinkList({ domains, editLink }) {
    const [selectedRows, setSelectedRows] = useState([]);
    const queryClient = useQueryClient()

    const deleteDeepLink = useMutation({
        mutationFn: ({ id }) => deepLinkService.deleteDeepLink(id),
        mutationKey: ['DeleteDeepLink']
    })

    const selectAll = (e) => {
        if (e.target.checked) {
            setSelectedRows(domains.map((ele) => ele.short_url))
        } else {
            setSelectedRows([])
        }

    }

    if (domains?.length === 0) {
        return <>No Record Found</>
    }

    if (!Array.isArray(domains)) {
        return (<Loader color="blue" />)
    }

    const onClick = (action, id) => {

        switch (action) {
            case "Delete":
                modals.openConfirmModal({
                    title: 'Delete This Link',
                    centered: true,
                    children: (
                        <Text size="sm">
                            Are you sure you want to delete this Dynamic Link? This action is destructive and you will have
                            to contact support to restore your data.
                        </Text>
                    ),
                    labels: { confirm: 'Delete Link', cancel: "No don't delete it" },
                    confirmProps: { color: 'red' },
                    onCancel: () => console.log('Cancel'),
                    onConfirm: () => {
                        deleteDeepLink.mutate({ id }, {
                            onSuccess: (data) => {
                                toast.success(data.message)
                                queryClient.invalidateQueries({
                                    queryKey: ['getDomain']
                                })
                            }
                        });

                    }
                });
                break;
            case "Edit":
                editLink(id)
                break;
            default:
                break;
        }

    }

    const rows = domains?.map((element) => (
        <Table.Tr
            key={element.name}
            bg={selectedRows.includes(element.short_url) ? 'var(--mantine-color-blue-light)' : undefined}
        >
            <Table.Td>
                <Checkbox
                    aria-label="Select row"
                    checked={selectedRows.includes(element.short_url)}
                    onChange={(event) =>
                        setSelectedRows(
                            event.currentTarget.checked
                                ? [...selectedRows, element.short_url]
                                : selectedRows.filter((url) => url !== element.short_url)
                        )
                    }
                />
            </Table.Td>
            <Table.Td>{element.deep_link_name}</Table.Td>
            <Table.Td>{element.short_url}</Table.Td>
            <Table.Td>{element.deep_link_url}</Table.Td>
            <Table.Td>{utcToLocal(element.created_at)}</Table.Td>
            <Table.Td>
                <div className=' flex gap-2'>
                    <CpButton link={element.short_url}></CpButton>
                    <Tooltip label={"Delete"} withArrow position="top">
                        <ActionIcon
                            variant="default"
                            className="dark:bg-primary dark:hover:bg-softPrimary dark:text-white  "
                            onClick={() => onClick("Delete", element.id)}
                        >
                            <IconTrash className="dark:text-white  text-red-600 " />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label={"Edit"} withArrow position="top">
                        <ActionIcon
                            className="dark:bg-primary dark:hover:bg-softPrimary dark:hover:text-white "
                            variant="default"
                            onClick={() => onClick("Edit", element)}
                        // disabled={expired}
                        >
                            <IconEdit className="dark:text-white text-black" />
                        </ActionIcon>
                    </Tooltip>
                </div>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <Stack bg="var(--mantine-color-body)">
            <Table striped withTableBorder highlightOnHover>

                <Table.Thead>
                    <Table.Tr>
                        <Table.Th> <Checkbox onChange={selectAll}> </Checkbox> </Table.Th>
                        {
                            tableHead.map(({ title, id }) => (
                                <Table.Th key={id} className=' text-muted text-xs' >{title}</Table.Th>
                            ))
                        }

                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
            </Table>
        </Stack>
    );
}
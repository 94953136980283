import "./Home.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/dates/styles.css";
import { ModalsProvider } from "@mantine/modals";

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <RouterProvider router={router} />
      </ModalsProvider>
      <ToastContainer />
    </MantineProvider>
  );
}

export default App;
